import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Widget } from "@typeform/embed-react";

import { Loading } from "components/Loading";

import { NEW_REGISTRATION_MUTATION } from "../graphql/mutations";
import { GET_APPLICATION_PANEL_DATA, PARTICIPANT_HISTORY_DETAILS } from "../graphql/queries";
import { useMutation, useQuery, useApolloClient } from "@apollo/client";

import useAuth from "auth";

const RegistrationForm = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const { user } = useAuth();

  const [widgetLoading, setWidgetLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [canNavigate, setCanNavigate] = useState(false);

  const errorMessageRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const { loading: applicationPanelDataLoading, data: applicationPanelData } = useQuery(
    GET_APPLICATION_PANEL_DATA,
    {},
  );

  const [newRegistrationMutation] = useMutation(NEW_REGISTRATION_MUTATION, {
    onCompleted: async (data) => {
      if (data.newRegistration.success) {
        setErrorMessage(null);
        // refetch history data before redirecting to the history page.
        await refecthHistoryData();
        setCanNavigate(true);
      } else {
        setErrorMessage(data.newRegistration.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const refecthHistoryData = async () => {
    await client.query({
      query: PARTICIPANT_HISTORY_DETAILS,
      variables: { userId: parseInt(user.userID) },
    });
  };

  useEffect(() => {
    if (canNavigate) navigate(`/history/${user.userID}`);
  }, [canNavigate]);

  const handleFormSubmit = async (event) => {
    setSubmitting(true);
    newRegistrationMutation({
      variables: { formId: event.formId, responseId: event.response_id },
    });
  };

  const handleWidgetLoad = () => {
    setWidgetLoading(false);
  };

  if (applicationPanelDataLoading) return <Loading />;

  if (submitting) return <Loading />;

  return (
    <>
      {widgetLoading && <Loading />}
      <div ref={errorMessageRef}></div> {/* Error message ref */}
      {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
      <Widget
        id={applicationPanelData.applicationPanelData.participantsFormId}
        style={{ width: "100%", height: "100vh" }}
        onSubmit={handleFormSubmit}
        onReady={handleWidgetLoad}
      />
    </>
  );
};

export default RegistrationForm;
