import React from "react";
import { useTranslation } from "react-i18next";

import { formatTime } from "helpers/common";

import { Table } from "antd";

import moment from "moment";

const AttendanceTable = ({ data, loading }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const uniqueCompanyNames = Array.from(
    new Set(
      data.map(
        (item) =>
          item.registration.assignedJobOpportunity?.companyRequest?.[
            `companyName${currentLanguage === "en" ? "En" : "Ar"}`
          ],
      ),
    ),
  )
    .filter((name) => !!name)
    .sort((a, b) => a.localeCompare(b));

  const uniqueJobTitles = Array.from(
    new Set(data.map((item) => item.registration.assignedJobOpportunity?.jobTitle)),
  )
    .filter((title) => !!title)
    .sort((a, b) => a.localeCompare(b));

  const columns = [
    {
      title: t("dashboard.participantName"),
      key: "participantName",
      sorter: (a, b) =>
        a.registration.participantProfile.fullNameEn.length -
        b.registration.participantProfile.fullNameEn.length,
      render: (obj) =>
        obj.registration.participantProfile["fullName" + (currentLanguage === "en" ? "En" : "Ar")],
    },
    {
      title: t("dashboard.mobileNumber"),
      key: "mobileNumber",
      render: (obj) => (
        <span style={{ unicodeBidi: "embed", direction: "ltr" }}>
          {obj.registration.participantProfile.mobileNumber}
        </span>
      ),
    },
    {
      title: t("dashboard.email"),
      key: "email",
      sorter: (a, b) =>
        a.registration.participantProfile.email.length -
        b.registration.participantProfile.email.length,
      render: (obj) => (
        <span className="english-field">{obj.registration.participantProfile.email}</span>
      ),
    },
    {
      title: t("dashboard.attendanceTable.date"),
      key: "date",
      dataIndex: "date",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: t("dashboard.attendanceTable.checkInTime"),
      key: "checkInTime",
      dataIndex: "checkInTime",
      sorter: (a, b) =>
        moment(a.checkInTime, "HH:mm:ss").unix() - moment(b.checkInTime, "HH:mm:ss").unix(),
      render: (checkInTime) => (checkInTime ? formatTime(checkInTime, currentLanguage) : "-"),
    },
    {
      title: t("dashboard.attendanceTable.checkInLocation"),
      key: "checkInLocation",
      render: (obj) => {
        const checkInLatitude = obj.checkInLatitude;
        const checkInLongitude = obj.checkInLongitude;

        if (checkInLatitude && checkInLongitude) {
          const googleMapsUrl = `https://www.google.com/maps?q=${checkInLatitude},${checkInLongitude}`;

          return (
            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
              {t("dashboard.attendanceTable.viewOnGoogleMaps")}
            </a>
          );
        }
        return "-";
      },
    },
    {
      title: t("dashboard.attendanceTable.checkOutTime"),
      key: "checkOutTime",
      dataIndex: "checkOutTime",
      sorter: (a, b) =>
        moment(a.checkOutTime, "HH:mm:ss").unix() - moment(b.checkOutTime, "HH:mm:ss").unix(),
      render: (checkOutTime) => (checkOutTime ? formatTime(checkOutTime, currentLanguage) : "-"),
    },
    {
      title: t("dashboard.attendanceTable.checkOutLocation"),
      key: "checkOutLocation",
      render: (obj) => {
        const checkOutLatitude = obj.checkOutLatitude;
        const checkOutLongitude = obj.checkOutLongitude;

        if (checkOutLatitude && checkOutLongitude) {
          const googleMapsUrl = `https://www.google.com/maps?q=${checkOutLatitude},${checkOutLongitude}`;

          return (
            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
              {t("dashboard.attendanceTable.viewOnGoogleMaps")}
            </a>
          );
        }
        return "-";
      },
    },
    {
      title: t("dashboard.assignedCompanyName"),
      key: "assignedCompanyName",
      filters: [
        { text: "-", value: "-" },
        ...uniqueCompanyNames.map((name) => ({
          text: name,
          value: name,
        })),
      ],
      onFilter: (value, record) => {
        const companyName =
          record.registration.assignedJobOpportunity?.companyRequest?.[
            `companyName${currentLanguage === "en" ? "En" : "Ar"}`
          ];
        return value === "-" ? !companyName : companyName === value;
      },
      render: (obj) =>
        obj.registration.assignedJobOpportunity?.companyRequest?.[
          `companyName${currentLanguage === "en" ? "En" : "Ar"}`
        ] || "-",
    },
    {
      title: t("dashboard.assignedJobTitle"),
      key: "jobTitle",
      filters: [
        { text: "-", value: "-" },
        ...uniqueJobTitles.map((title) => ({ text: title, value: title })),
      ],
      onFilter: (value, record) => {
        const jobTitle = record.registration.assignedJobOpportunity?.jobTitle;
        return value === "-" ? !jobTitle : jobTitle === value;
      },
      render: (obj) => obj.registration.assignedJobOpportunity?.jobTitle || "-",
    },
  ];

  return (
    <div style={{ overflowX: "clip" }}>
      <Table
        columns={columns}
        pagination={{ position: ["bottomRight"] }}
        dataSource={data.map((item) => ({ ...item, key: item.id }))}
        scroll={{ x: true }}
        loading={loading}
      />
    </div>
  );
};

export default AttendanceTable;
