import styled from "styled-components";

const RequestFormContainer = styled.div`
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    color: #000;
    font-weight: bold;
  }

  .ant-checkbox-wrapper {
    font-weight: bold;
    font-size: 14px;
  }

  .ant-checkbox-wrapper::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

  .form-item-wrapper .ant-form-item-label {
    display: block !important;
    text-align: left;
  }

  .form-item-wrapper .ant-form-item-control {
    display: block;
  }

  .react-tel-input .form-control {
    border-radius: 6px;
    width: 100%;
    height: 40px;
    color: #000;
    font-size: 14px;
  }

  .ant-input-number-input {
    height: 40px;
  }

  .ant-card-head {
    min-height: 0px;
    height: 46px;
    padding: 12px 16px;
  }

  .custom-button:hover {
    background-color: #35b4a7 !important;
    color: #fff !important;
  }
`;

export default RequestFormContainer;
