import { Modal, Table, Tag } from "antd";

import { useTranslation } from "react-i18next";

import { REQUEST_DETAILS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import { Loading } from "components/Loading";

import ModalStyles from "./styles";

import {
  getRegistrationPaymentStatusColor,
  getRegistrationStatusColor,
} from "helpers/registration";

import InternInformationPDF from "./InternInformationPDF";

const AssignedInternsModal = ({ isModalOpen, handleOk, handleCancel, requestID }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { loading: requestDetailsDataLoading, data: requestDetailsData } = useQuery(
    REQUEST_DETAILS,
    { variables: { id: requestID } },
  );

  const columns = [
    {
      title: t("dashboard.participantName"),
      key: "participantName",
      render: (obj) =>
        obj.participantProfile["fullName" + (currentLanguage === "en" ? "En" : "Ar")],
    },
    {
      title: t("dashboard.age"),
      key: "age",
      render: (obj) => obj.participantProfile.age,
    },
    {
      title: t("dashboard.gender"),
      key: "gender",
      render: (obj) => t(`dashboard.genderOptions.${obj.participantProfile.gender}`),
    },
    {
      title: t("dashboard.assignedJobTitle"),
      key: "jobTitle",
      render: (obj) => obj.assignedJobOpportunity?.jobTitle || "-",
    },
    {
      title: t("dashboard.paymentStatus"),
      key: "paymentStatus",
      render: (obj) => (
        <Tag color={getRegistrationPaymentStatusColor(obj.paymentStatus)}>
          {t(`dashboard.${obj.paymentStatus.toLowerCase()}`).toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t("dashboard.status"),
      key: "status",
      render: (obj) => (
        <Tag color={getRegistrationStatusColor(obj.status)}>
          {t(`dashboard.${obj.status.toLowerCase()}`).toUpperCase()}
        </Tag>
      ),
    },
    {
      title: t("dashboard.requestsTable.assignedInternsModal.downloadPDF"),
      key: "downloadPDF",
      render: (obj) => <InternInformationPDF obj={obj} />,
    },
  ];

  return (
    <Modal
      title={t("dashboard.requestsTable.assignedInternsModal.title")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      key={requestID}
      width={1000}
      footer={null}
    >
      {requestDetailsDataLoading ? (
        <Loading />
      ) : (
        <ModalStyles currentLanguage={currentLanguage}>
          <Table
            dataSource={requestDetailsData?.requestDetails.assignedInterns.map((item) => ({
              ...item,
              key: item.id,
            }))}
            columns={columns}
            pagination={false}
          />
        </ModalStyles>
      )}
    </Modal>
  );
};

export default AssignedInternsModal;
