import styled from "styled-components";

const CompanyProfileContainer = styled.div`
  .ant-result-subtitle {
    direction: ltr;
    font-family: "Rubik", sans-serif;
  }

  .react-tel-input .form-control {
    border-radius: 6px;
    width: 100%;
    height: 40px;
    color: #000;
    font-size: 14px;
  }
`;

export default CompanyProfileContainer;
