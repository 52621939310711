import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Layout, Button, Row, Col, Typography, Form, Input, InputNumber } from "antd";

import { GET_APPLICATION_PANEL_DATA } from "../../graphql/queries";
import { UPDATE_APPLICATION_PANEL_MUTATION } from "../../graphql/mutations";
import { useMutation, useQuery } from "@apollo/client";

import { Loading } from "components/Loading";
import ApplicationPanelFormContainer from "./styles";

const { Title, Text } = Typography;
const { Content } = Layout;

const ApplicationPanelForm = () => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const errorMessageRef = useRef(null);
  const successMessageRef = useRef(null);

  const {
    loading: applicationPanelDataLoading,
    data: applicationPanelData,
    refetch: applicationPanelDataRefetch,
  } = useQuery(GET_APPLICATION_PANEL_DATA, {});

  const [updateApplicationPanelMutation] = useMutation(UPDATE_APPLICATION_PANEL_MUTATION, {
    onCompleted: (data) => {
      if (data.updateApplicationPanel.success) {
        setErrorMessage(null);
        setSuccessMessage(t("dashboard.applicationPanelForm.updatedSuccessfullyMessage"));
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
        applicationPanelDataRefetch();
      } else {
        setErrorMessage(data.updateApplicationPanel.errors[0]);
        setSuccessMessage(null);
        scrollToError();
      }
    },
  });

  const onFinish = (values) => {
    updateApplicationPanelMutation({
      variables: { ...values, applicationPanelId: applicationPanelData.applicationPanelData.id },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  if (applicationPanelDataLoading) return <Loading />;

  return (
    <ApplicationPanelFormContainer>
      <Layout className="p-3 p-md-5 m-0">
        <Content>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Title
                className="mb-5 mt-0"
                style={{
                  fontSize: "45px",
                  whiteSpace: "break-spaces",
                }}
              >
                {t("dashboard.applicationPanelForm.title")}
              </Title>

              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
                scrollToFirstError={true}
                initialValues={applicationPanelData.applicationPanelData}
              >
                <div ref={errorMessageRef}></div> {/* Error message ref */}
                {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
                <div ref={successMessageRef}></div> {/* Success message ref */}
                {successMessage && <p className="text-success">{successMessage}</p>}
                <Row gutter={[24, 0]}>
                  <Col xs={24}>
                    <Form.Item
                      label={t("dashboard.applicationPanelForm.participantsFormId")}
                      name="participantsFormId"
                      rules={[
                        {
                          required: true,
                          message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                        },
                      ]}
                    >
                      <Input maxLength={10} showCount />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col xs={24}>
                    <Form.Item
                      label={t("dashboard.applicationPanelForm.participationFees")}
                      name="participationFees"
                      rules={[
                        {
                          required: true,
                          message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col xs={24} className="mb-3">
                    <Text
                      style={{
                        fontSize: "16px",
                      }}
                    >
                      {t("dashboard.applicationPanelForm.participantsAgeGroup")}:
                    </Text>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={t("dashboard.applicationPanelForm.participantsAgeFrom")}
                      name="participantsAgeFrom"
                      rules={[
                        {
                          required: true,
                          message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <Form.Item
                      label={t("dashboard.applicationPanelForm.participantsAgeTo")}
                      name="participantsAgeTo"
                      rules={[
                        {
                          required: true,
                          message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col xs={24}>
                    <Form.Item
                      label={t("dashboard.applicationPanelForm.malesWhatsappCommunityLink")}
                      name="malesWhatsappCommunityLink"
                      rules={[
                        {
                          type: "url",
                          message: t("requestForm.validUrlMessage"),
                        },
                        {
                          required: true,
                          message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col xs={24}>
                    <Form.Item
                      label={t("dashboard.applicationPanelForm.femalesWhatsappCommunityLink")}
                      name="femalesWhatsappCommunityLink"
                      rules={[
                        {
                          type: "url",
                          message: t("requestForm.validUrlMessage"),
                        },
                        {
                          required: true,
                          message: t("dashboard.applicationPanelForm.requiredFieldMessage"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[24, 0]}>
                  <Col xs={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                        {t("dashboard.applicationPanelForm.saveBtn")}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Content>
      </Layout>
    </ApplicationPanelFormContainer>
  );
};

export default ApplicationPanelForm;
