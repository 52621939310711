import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Row, Col, Result } from "antd";

import { COMPANY_PROFILE_INFORMATION } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import CompanyProfileContainer from "./styles";

import ProfileInfoCard from "./ProfileInfoCard";

import { Loading } from "components/Loading";

const CompanyProfile = () => {
  const { t } = useTranslation();

  const { userID } = useParams();

  const {
    loading: companyProfileDataLoading,
    error: companyProfileDataError,
    data: companyProfileData,
    refetch: companyProfileDataRefetch,
  } = useQuery(COMPANY_PROFILE_INFORMATION, {
    variables: { userId: parseInt(userID) },
  });

  if (companyProfileDataLoading) return <Loading />;

  if (companyProfileDataError)
    return (
      <CompanyProfileContainer>
        <Result
          status="error"
          title={t("auth.companyProfile.errorFetchingDataMessage")}
          subTitle={companyProfileDataError.message}
        />
      </CompanyProfileContainer>
    );

  return (
    <CompanyProfileContainer>
      <Row gutter={[24, 40]} className="mb-5">
        <Col xs={24}>
          <ProfileInfoCard
            profileInfo={companyProfileData.companyProfile}
            userID={userID}
            companyProfileDataRefetch={companyProfileDataRefetch}
          />
        </Col>
      </Row>
    </CompanyProfileContainer>
  );
};

export default CompanyProfile;
