import { gql } from "@apollo/client";

export const SIGNIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      success
      errors
    }
  }
`;

export const COMPANY_SIGNUP_MUTATION = gql`
  mutation companyRegister(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $nameEn: String!
    $nameAr: String!
    $descriptionEn: String!
    $descriptionAr: String!
    $contactPersonName: String!
    $contactPersonNumber: String!
    $logo: Upload!
  ) {
    companyRegister(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      nameEn: $nameEn
      nameAr: $nameAr
      descriptionEn: $descriptionEn
      descriptionAr: $descriptionAr
      contactPersonName: $contactPersonName
      contactPersonNumber: $contactPersonNumber
      logo: $logo
    ) {
      token
      success
      errors
    }
  }
`;

export const PARTICIPANT_SIGNUP_MUTATION = gql`
  mutation participantRegister(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $fullNameEn: String!
    $fullNameAr: String!
    $civilIdNumber: String!
    $dateOfBirth: String!
    $gender: String!
    $mobileNumber: String!
    $guardianMobileNumber: String!
    $guardianRelation: String!
    $emergencyContactNumber: String!
    $emergencyContactRelation: String!
    $languages: [String]!
    $otherLanguages: String
    $chronicDiseasesYesNo: String!
    $chronicDiseases: String
    $nationality: String!
    $motherNationality: String!
    $countryOfResidence: String!
  ) {
    participantRegister(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      fullNameEn: $fullNameEn
      fullNameAr: $fullNameAr
      civilIdNumber: $civilIdNumber
      dateOfBirth: $dateOfBirth
      gender: $gender
      mobileNumber: $mobileNumber
      guardianMobileNumber: $guardianMobileNumber
      guardianRelation: $guardianRelation
      emergencyContactNumber: $emergencyContactNumber
      emergencyContactRelation: $emergencyContactRelation
      languages: $languages
      otherLanguages: $otherLanguages
      chronicDiseasesYesNo: $chronicDiseasesYesNo
      chronicDiseases: $chronicDiseases
      nationality: $nationality
      motherNationality: $motherNationality
      countryOfResidence: $countryOfResidence
    ) {
      token
      success
      errors
    }
  }
`;

export const UPDATE_APPLICATION_PANEL_MUTATION = gql`
  mutation updateApplicationPanel(
    $applicationPanelId: ID!
    $participantsFormId: String
    $participationFees: Int
    $participantsAgeFrom: Int
    $participantsAgeTo: Int
    $malesWhatsappCommunityLink: String
    $femalesWhatsappCommunityLink: String
  ) {
    updateApplicationPanel(
      applicationPanelId: $applicationPanelId
      participantsFormId: $participantsFormId
      participationFees: $participationFees
      participantsAgeFrom: $participantsAgeFrom
      participantsAgeTo: $participantsAgeTo
      malesWhatsappCommunityLink: $malesWhatsappCommunityLink
      femalesWhatsappCommunityLink: $femalesWhatsappCommunityLink
    ) {
      success
      errors
    }
  }
`;

export const NEW_REGISTRATION_MUTATION = gql`
  mutation newRegistration($formId: String!, $responseId: String!) {
    newRegistration(formId: $formId, responseId: $responseId) {
      success
      errors
    }
  }
`;

export const PAYMENT_METHODS_LIST_MUTATION = gql`
  mutation paymentMethodsList {
    paymentMethodsList {
      paymentMethods
      success
      errors
    }
  }
`;

export const NEW_TRANSACTION_MUTATION = gql`
  mutation newTransaction($paymentMethodId: Int!, $registrationId: Int!) {
    newTransaction(paymentMethodId: $paymentMethodId, registrationId: $registrationId) {
      paymentUrl
      success
      errors
    }
  }
`;

export const PARTICIPANT_PROFILE_UPDATE = gql`
  mutation participantProfileUpdate(
    $userId: Int!
    $email: String!
    $inputData: ParticipantProfileInput!
  ) {
    participantProfileUpdate(userId: $userId, email: $email, inputData: $inputData) {
      success
      errors
      token
    }
  }
`;

export const NEW_COMPANY_REQUEST_MUTATION = gql`
  mutation newCompanyRequest(
    $trainingType: String!
    $specialRequirements: String!
    $certificateCopy: Upload
    $interviewersInfoForm: [InterviewerInput!]!
    $locationsInfoForm: [LocationInput!]!
    $accurateInformation: Boolean!
  ) {
    newCompanyRequest(
      trainingType: $trainingType
      specialRequirements: $specialRequirements
      certificateCopy: $certificateCopy
      interviewersInfoForm: $interviewersInfoForm
      locationsInfoForm: $locationsInfoForm
      accurateInformation: $accurateInformation
    ) {
      success
      errors
    }
  }
`;

export const EDIT_COMPANY_REQUEST_MUTATION = gql`
  mutation editCompanyRequest(
    $requestId: ID!
    $trainingType: String!
    $specialRequirements: String!
    $certificateCopy: Upload
    $interviewersInfoForm: [InterviewerInput!]!
    $locationsInfoForm: [LocationInput!]!
    $accurateInformation: Boolean!
  ) {
    editCompanyRequest(
      requestId: $requestId
      trainingType: $trainingType
      specialRequirements: $specialRequirements
      certificateCopy: $certificateCopy
      interviewersInfoForm: $interviewersInfoForm
      locationsInfoForm: $locationsInfoForm
      accurateInformation: $accurateInformation
    ) {
      success
      errors
    }
  }
`;

export const UPDATE_REGISTRATION_STATUS_MUTATION = gql`
  mutation updateRegistration($registrationId: ID!, $status: String!) {
    updateRegistration(registrationId: $registrationId, status: $status) {
      registration {
        id
        status
      }
    }
  }
`;

export const UPDATE_PAYMENT_STATUS_MUTATION = gql`
  mutation updateRegistration($registrationId: ID!, $paymentStatus: String!) {
    updateRegistration(registrationId: $registrationId, paymentStatus: $paymentStatus) {
      registration {
        id
        status
      }
    }
  }
`;

export const ASSIGN_TO_COMPANY_MUTATION = gql`
  mutation updateRegistration($registrationId: ID!, $assignedJobOpportunity: ID!) {
    updateRegistration(
      registrationId: $registrationId
      assignedJobOpportunity: $assignedJobOpportunity
    ) {
      registration {
        id
        assignedJobOpportunity {
          id
        }
      }
    }
  }
`;

export const CAN_CHECK_IN_OUT_MUTATION = gql`
  mutation canCheckInOut($registrationId: ID!, $date: Date!) {
    canCheckInOut(registrationId: $registrationId, date: $date) {
      canCheckIn
      canCheckOut
      success
      errors
    }
  }
`;

export const NEW_ATTENDANCE_RECORD = gql`
  mutation newAttendanceRecord(
    $registrationId: ID!
    $date: Date!
    $checkInTime: Time
    $checkOutTime: Time
    $checkInLatitude: Float
    $checkInLongitude: Float
    $checkOutLatitude: Float
    $checkOutLongitude: Float
  ) {
    newAttendanceRecord(
      registrationId: $registrationId
      date: $date
      checkInTime: $checkInTime
      checkOutTime: $checkOutTime
      checkInLatitude: $checkInLatitude
      checkInLongitude: $checkInLongitude
      checkOutLatitude: $checkOutLatitude
      checkOutLongitude: $checkOutLongitude
    ) {
      success
      errors
      eventType
    }
  }
`;

export const COMPANY_PROFILE_UPDATE = gql`
  mutation companyProfileUpdate($userId: Int!, $email: String!, $inputData: CompanyProfileInput!) {
    companyProfileUpdate(userId: $userId, email: $email, inputData: $inputData) {
      success
      errors
      token
    }
  }
`;

export const SEND_CONTACT_US_MESSAGE = gql`
  mutation sendEmail(
    $name: String!
    $mobileNumber: String!
    $email: String
    $subject: String!
    $message: String!
  ) {
    sendEmail(
      name: $name
      mobileNumber: $mobileNumber
      email: $email
      subject: $subject
      message: $message
    ) {
      success
    }
  }
`;

export const SEND_WHATSAPP_MESSAGE_MUTATION = gql`
  mutation sendWhatsapp($templateName: String!, $to: String!) {
    sendWhatsapp(templateName: $templateName, to: $to) {
      success
      errors
    }
  }
`;

export const SIGNUP_WITH_GOOGLE_MUTATION = gql`
  mutation signupWithGoogle($fullName: String!, $email: String!, $uid: String!) {
    signupWithGoogle(email: $email, fullName: $fullName, uid: $uid) {
      token
      success
      errors
    }
  }
`;
