import { useQuery } from "@apollo/client";
import { Col, Result, Row, Typography } from "antd";
import { Loading } from "components/Loading";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  PARTICIPANT_HISTORY_DETAILS,
  PARTICIPANT_PROFILE_INFORMATION,
} from "../../../../graphql/queries";
import ApplyNowCard from "../ApplyNowCard";
import ParticipantProfileContainer from "../styles";
import HistoryCard from "./HistoryCard";

const { Title } = Typography;

const ParticipantHistory = () => {
  const { userID } = useParams();

  const { t } = useTranslation();

  const {
    loading: participantProfileDataLoading,
    data: participantProfileData,
    error: participantProfileDataError,
  } = useQuery(PARTICIPANT_PROFILE_INFORMATION, {
    variables: { userId: parseInt(userID) },
  });

  const {
    loading: participantHistoryDataLoading,
    data: participantHistoryData,
    refetch: participantHistoryDataRefetch,
    error: participantHistoryDataError,
  } = useQuery(PARTICIPANT_HISTORY_DETAILS, {
    variables: { userId: parseInt(userID) },
  });

  if (participantHistoryDataLoading || participantProfileDataLoading) return <Loading />;

  if (participantProfileDataError)
    return (
      <ParticipantProfileContainer>
        <Result
          status="error"
          title={t("auth.participantProfile.errorFetchingDataMessage")}
          subTitle={participantProfileDataError.message}
        />
      </ParticipantProfileContainer>
    );

  if (participantHistoryDataError)
    return (
      <ParticipantProfileContainer>
        <Result
          status="error"
          title={t("auth.participantProfile.errorFetchingDataMessage")}
          subTitle={participantHistoryDataError.message}
        />
      </ParticipantProfileContainer>
    );

  return (
    <ParticipantProfileContainer>
      <Row gutter={[24, 40]} className="mb-5">
        <Col xs={24} lg={8}>
          <ApplyNowCard
            profileInfo={participantProfileData.participantProfile}
            showApplyNowBtn={false}
          />
        </Col>

        <Col xs={24} lg={16}>
          <Col xs={24}>
            <Title level={3} className="mb-0">
              {t("auth.participantProfile.historySectionTitle")}
            </Title>
          </Col>

          {participantHistoryData?.registrations?.length ? (
            participantHistoryData?.registrations?.map((obj) => (
              <HistoryCard
                key={obj.id}
                registrationObj={obj}
                refetchData={participantHistoryDataRefetch}
                gender={participantProfileData.participantProfile.gender}
              />
            ))
          ) : (
            <Result status="info" title={t("auth.participantProfile.noHistoryMessage")} />
          )}
        </Col>
      </Row>
    </ParticipantProfileContainer>
  );
};

export default ParticipantHistory;
