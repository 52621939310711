import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import arAuth from "./ar/auth.json";
import arDashboard from "./ar/dashboard.json";
import arFooter from "./ar/footer.json";
import arHome from "./ar/home.json";
import arNavbar from "./ar/navbar.json";
import arPayment from "./ar/payment.json";
import arRequestForm from "./ar/requestForm.json";

import enAuth from "./en/auth.json";
import enDashboard from "./en/dashboard.json";
import enFooter from "./en/footer.json";
import enHome from "./en/home.json";
import enNavbar from "./en/navbar.json";
import enPayment from "./en/payment.json";
import enRequestForm from "./en/requestForm.json";

// eslint-disable-next-line import/no-named-as-default-member
i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // Add your translation resources here
    resources: {
      en: {
        translation: {
          home: enHome,
          navbar: enNavbar,
          footer: enFooter,
          auth: enAuth,
          dashboard: enDashboard,
          payment: enPayment,
          requestForm: enRequestForm,
        },
      },
      ar: {
        translation: {
          home: arHome,
          navbar: arNavbar,
          footer: arFooter,
          auth: arAuth,
          dashboard: arDashboard,
          payment: arPayment,
          requestForm: arRequestForm,
        },
      },
      // Add more languages as needed
    },
    lng: "ar", // Set the default language
    fallbackLng: "en", // Fallback language in case the translation is missing
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

// Add a language change event listener
i18next.on("languageChanged", (lng) => {
  // This callback will be executed after the language is changed
  if (lng === "ar") {
    document.dir = "rtl";
  } else {
    document.dir = "ltr";
  }
});

export default i18next;
