import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { COMPANY_PROFILE_UPDATE } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";

import useAuth from "../../../auth";

import { Card, Button, Form, Input, Row, Col } from "antd";

const CompanyInfoCard = ({ profileInfo, userID, companyProfileDataRefetch }) => {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const [companyLogo, setCompanyLogo] = useState(null);
  const [contactPersonNumber, setContactPersonNumber] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const errorMessageRef = useRef(null);
  const successMessageRef = useRef(null);

  const { signIn } = useAuth();

  const [updateProfileMutation] = useMutation(COMPANY_PROFILE_UPDATE, {
    onCompleted: (data) => {
      if (data.companyProfileUpdate.success) {
        if (data.companyProfileUpdate.token) signIn({ token: data.companyProfileUpdate.token });
        setErrorMessage(null);
        setSuccessMessage(t("auth.companyProfile.updatedSuccessfullyMessage"));
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
        companyProfileDataRefetch();
      } else {
        setErrorMessage(data.companyProfileUpdate.errors[0]);
        setSuccessMessage(null);
        scrollToError();
      }
      setSubmitting(false);
    },
  });

  const onFinish = (values) => {
    setSubmitting(true);
    const inputData = {
      ...values,
    };

    const email = inputData["email"];
    delete inputData["email"];

    if (contactPersonNumber) inputData["contactPersonNumber"] = contactPersonNumber;
    else delete inputData["contactPersonNumber"];

    if (companyLogo) inputData["logo"] = companyLogo;
    else delete inputData["logo"];

    updateProfileMutation({
      variables: {
        userId: parseInt(userID),
        email,
        inputData: { ...inputData },
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const { logo, ...initialValues } = profileInfo;

  return (
    <Card style={{ width: "100%" }}>
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        initialValues={initialValues}
      >
        <div ref={errorMessageRef}></div> {/* Error message ref */}
        {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
        <div ref={successMessageRef}></div> {/* Success message ref */}
        {successMessage && <p className="text-success">{successMessage}</p>}
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={16}>
            <Form.Item
              label={t("auth.signUp.emailField")}
              name="email"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
                {
                  type: "email",
                  message: t("auth.signUp.validEmailAddressMessage"),
                },
              ]}
            >
              <Input className="english-field" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.companyNameEnField")}
              name="nameEn"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input maxLength={50} showCount className="english-field" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.companyNameArField")}
              name="nameAr"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input maxLength={50} showCount className="arabic-field" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.companyDescriptionEnField")}
              name="descriptionEn"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input.TextArea rows={5} className="english-field" />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.companyDescriptionArField")}
              name="descriptionAr"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input.TextArea rows={5} className="arabic-field" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.contactPersonNameField")}
              name="contactPersonName"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <Input maxLength={50} showCount />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12}>
            <Form.Item
              label={t("auth.signUp.contactPersonNumberField")}
              name="contactPersonNumber"
              rules={[
                {
                  required: true,
                  message: t("auth.signUp.requiredFieldMessage"),
                },
              ]}
            >
              <PhoneInput
                className="english-field"
                country="kw"
                placeholder=""
                excludeCountries={["il"]}
                preferredCountries={["kw"]}
                onChange={(value, data) => {
                  const countryCode = data.dialCode;
                  const index = value.indexOf(countryCode);
                  const length = countryCode.length;
                  setContactPersonNumber(countryCode + "-" + value.slice(index + length));
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                <>
                  {t("auth.signUp.companyLogoField")}
                  &nbsp;
                  <a href={logo} target="_blank" rel="noopener noreferrer">
                    ({t("auth.companyProfile.viewCurrentLogo")})
                  </a>
                </>
              }
              name="logo"
            >
              <input
                type="file"
                accept="image/*"
                onChange={(event) => {
                  setCompanyLogo(event.target.files[0]);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Form.Item className="text-center">
              <Button
                style={{ width: "60%" }}
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={submitting}
              >
                {t("auth.companyProfile.saveBtn")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CompanyInfoCard;
