import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Col, Flex, Row, Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";

import SearchBar from "components/inputs/SearchBar";

import { ATTENDANCE_RECORDS_LIST } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import AttendanceTable from "./AttendanceTable";
import FilterModal from "./FilterModal";

const AttendanceDashboard = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setFilterQuery] = useState("");
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);

  const { loading: attendanceRecordsDataLoading, data: attendanceRecordsData } = useQuery(
    ATTENDANCE_RECORDS_LIST,
    { variables: { filters: filterQuery, search: searchQuery } },
  );

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Flex
        vertical
        gap="middle"
        style={{
          backgroundColor: "white",
          padding: 24,
        }}
      >
        <Row className="searchbar-row">
          <Col xs={24} lg={12}>
            <SearchBar
              placeholder={t("dashboard.attendanceTable.searchPlaceholder")}
              value={searchQuery}
              onChange={handleChange}
            />
          </Col>

          <Col xs={24} lg={12} style={{ textAlign: currentLanguage === "ar" ? "left" : "right" }}>
            <Button
              type="primary"
              shape="circle"
              icon={<FilterOutlined />}
              className="filter-btn"
              onClick={() => setFilterModalOpen(true)}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <AttendanceTable
              data={attendanceRecordsData?.attendanceRecords || []}
              loading={attendanceRecordsDataLoading}
            />
          </Col>
        </Row>
      </Flex>

      <FilterModal
        isModalOpen={isFilterModalOpen}
        handleOk={() => setFilterModalOpen(false)}
        handleCancel={() => setFilterModalOpen(false)}
        setFilterQuery={setFilterQuery}
      />
    </>
  );
};

export default AttendanceDashboard;
