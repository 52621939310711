import "./assets/styles/custom.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import AboutSectionForm from "components/Dashboard/HomePageContentManagement/AboutSectionForm";
import FooterSectionForm from "components/Dashboard/HomePageContentManagement/FooterSectionForm";
import HeroSectionForm from "components/Dashboard/HomePageContentManagement/HeroSectionForm";
import OurStoryStatisticalItemsForm from "components/Dashboard/HomePageContentManagement/OurStoryStatisticalItemsForm";
import OurValuesForm from "components/Dashboard/HomePageContentManagement/OurValuesForm";
import ParticipatingEntitiesForm from "components/Dashboard/HomePageContentManagement/ParticipatingEntitiesForm";
import SocialMediaForm from "components/Dashboard/HomePageContentManagement/SocialMediaForm";
import TeamMembersForm from "components/Dashboard/HomePageContentManagement/TeamMembersForm";
import SponsorsForm from "components/Dashboard/HomePageContentManagement/SponsorsForm";
import PageLayout from "components/layout/PageLayout";
import { Loading } from "components/Loading";
import SideNav from "components/SideNav";
import AttendanceDashboardPage from "pages/AttendanceDashboard";
import RequestForm from "pages/RequestForm";
import RequestsDashboardPage from "pages/RequestsDashboard";
import RequestUpdateFromPage from "pages/RequestUpdateForm";
import UserType from "pages/UserType";
import { Navigate, Route, Routes } from "react-router-dom";

import useAuth from "./auth";
import ApplicationPanel from "./pages/ApplicationPanel";
import Home from "./pages/Home";
import Invoice from "./pages/Invoice";
import History from "./pages/ParticipantHistory";
import PaymentMethod from "./pages/PaymentMethod";
import Profile from "./pages/Profile";
import RegistrationForm from "./pages/RegistrationForm";
import RegistrationsDashboardPage from "./pages/RegistrationsDashboard";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";

function App() {
  const { isAuthenticated, userLoading, user } = useAuth();

  if (userLoading) return <Loading />;

  return (
    <div className="App">
      <Routes>
        {/* Do not add PageLayout to homepage it will ruin */}
        <Route path="/" exact element={<Home />} />

        {!isAuthenticated && (
          <>
            <Route path="/user-type" exact element={<UserType />} />

            <Route path="/sign-up/:userType" exact element={<SignUp />} />

            <Route
              path="/sign-in"
              exact
              element={
                <PageLayout>
                  <SignIn />
                </PageLayout>
              }
            />
          </>
        )}

        {isAuthenticated && user.userType === "Participant" && (
          <>
            <Route
              exact
              path="/profile/:userID"
              element={
                <PageLayout>
                  <Profile />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/history/:userID"
              element={
                <PageLayout>
                  <History />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/register"
              element={
                <PageLayout>
                  <RegistrationForm />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/register/:registrationID/pay"
              element={
                <PageLayout>
                  <PaymentMethod />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/invoice/:paymentID"
              element={
                <PageLayout>
                  <Invoice />
                </PageLayout>
              }
            />
          </>
        )}

        {isAuthenticated && user.userType === "Staff" && (
          <>
            <Route
              exact
              path="/application-panel"
              element={
                <PageLayout>
                  <SideNav>
                    <ApplicationPanel />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/landing-page-settings/hero-section"
              element={
                <PageLayout>
                  <SideNav>
                    <HeroSectionForm />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/landing-page-settings/about-section"
              element={
                <PageLayout>
                  <SideNav>
                    <AboutSectionForm />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/landing-page-settings/team-members-section"
              element={
                <PageLayout>
                  <SideNav>
                    <TeamMembersForm />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/landing-page-settings/sponsors-section"
              element={
                <PageLayout>
                  <SideNav>
                    <SponsorsForm />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/landing-page-settings/our-values-section"
              element={
                <PageLayout>
                  <SideNav>
                    <OurValuesForm />
                  </SideNav>
                </PageLayout>
              }
            />
            <Route
              exact
              path="/dashboard/landing-page-settings/our-statistics-section"
              element={
                <PageLayout>
                  <SideNav>
                    <OurStoryStatisticalItemsForm />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/landing-page-settings/social-media-section"
              element={
                <PageLayout>
                  <SideNav>
                    <SocialMediaForm />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/landing-page-settings/participating-entities-section"
              element={
                <PageLayout>
                  <SideNav>
                    <ParticipatingEntitiesForm />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/landing-page-settings/footer-section"
              element={
                <PageLayout>
                  <SideNav>
                    <FooterSectionForm />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/registrations"
              element={
                <PageLayout>
                  <SideNav>
                    <RegistrationsDashboardPage />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/attendance"
              element={
                <PageLayout>
                  <SideNav>
                    <AttendanceDashboardPage />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/dashboard/requests"
              element={
                <PageLayout>
                  <SideNav>
                    <RequestsDashboardPage />
                  </SideNav>
                </PageLayout>
              }
            />

            <Route
              exact
              path="/requests/:requestID"
              element={
                <PageLayout>
                  <RequestUpdateFromPage />
                </PageLayout>
              }
            />
          </>
        )}

        {isAuthenticated && user.userType === "Company" && (
          <>
            <Route
              exact
              path="/request-form"
              element={
                <PageLayout>
                  <RequestForm />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/profile/:userID"
              element={
                <PageLayout>
                  <Profile />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/requests/:requestID"
              element={
                <PageLayout>
                  <RequestUpdateFromPage />
                </PageLayout>
              }
            />

            <Route
              exact
              path="/requests"
              element={
                <PageLayout>
                  <RequestsDashboardPage />
                </PageLayout>
              }
            />
          </>
        )}

        {/* Fallback route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
