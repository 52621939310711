import styled from "styled-components";

const ApplicationPanelFormContainer = styled.div`
  .ant-layout {
    background: #fff;
  }

  .layout-default .ant-layout-content {
    padding-top: 0;
  }

  .form-item-wrapper .ant-form-item-label {
    display: block !important;
    text-align: left;
  }

  .form-item-wrapper .ant-form-item-control {
    display: block;
  }

  .ant-input-number {
    width: 100%;
  }
`;

export default ApplicationPanelFormContainer;
