import { Modal } from "antd";

import { useTranslation } from "react-i18next";

import { REGISTRATION_QUESTION_ANSWERS } from "graphql/queries";
import { useQuery } from "@apollo/client";

import { Loading } from "components/Loading";

const RegistrationResponseModal = ({ isModalOpen, handleOk, handleCancel, registrationID }) => {
  const { t } = useTranslation();

  const { loading: registrationResponseLoading, data: registrationResponseData } = useQuery(
    REGISTRATION_QUESTION_ANSWERS,
    { variables: { registrationId: registrationID } },
  );

  return (
    <Modal
      title={t("dashboard.registrationFormResponseModal.modalTitle")}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      key={registrationID}
      width={800}
      footer={null}
    >
      {registrationResponseLoading ? (
        <Loading />
      ) : (
        <ul>
          {registrationResponseData.registrationQuestionAnswers.map((obj) => (
            <li style={{ listStyleType: "square", fontWeight: "bold" }} key={obj.id}>
              {obj.question}
              <ul>
                <li style={{ fontWeight: "normal" }}>{obj.answer}</li>
              </ul>
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};

export default RegistrationResponseModal;
