import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Menu, Button, Divider, Dropdown, Avatar } from "antd";
import {
  UserOutlined,
  LogoutOutlined,
  GlobalOutlined,
  HomeOutlined,
  FormOutlined,
  TableOutlined,
} from "@ant-design/icons";

import useAuth from "../../auth";

import enUS from "antd/locale/en_US";
import arEG from "antd/locale/ar_EG";

const RightMenu = ({ mode }) => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { isAuthenticated, logout, user } = useAuth();

  const [locale, setLocal] = useState(currentLanguage === "ar" ? arEG : enUS);

  const handleSwitchLanguage = () => {
    const localeValue = locale === enUS ? arEG : enUS;
    setLocal(localeValue);
    i18n.changeLanguage(currentLanguage === "ar" ? "en" : "ar");
  };

  const subMenuItems = [
    {
      label: t("navbar.rightMenu.homeTab"),
      key: "home",
      icon: <HomeOutlined />,
      onClick: () => {
        navigate("/");
      },
    },
  ];

  if (isAuthenticated && user.userType === "Participant")
    subMenuItems.push(
      {
        label: t("navbar.rightMenu.accountTab"),
        key: "account",
        icon: <UserOutlined />,
        onClick: () => {
          navigate(`/history/${user.userID}`);
        },
      },
      {
        label: t("navbar.rightMenu.profileTab"),
        key: "profile",
        icon: <UserOutlined />,
        onClick: () => {
          navigate(`/profile/${user.userID}`);
        },
      },
    );

  if (isAuthenticated && user.userType === "Staff")
    subMenuItems.push({
      label: t("navbar.rightMenu.dashboardTab"),
      key: "dashboard",
      icon: <TableOutlined />,
      onClick: () => {
        navigate("/application-panel");
      },
    });

  if (isAuthenticated && user.userType === "Company")
    subMenuItems.push(
      {
        label: t("navbar.rightMenu.profileTab"),
        key: "profile",
        icon: <UserOutlined />,
        onClick: () => {
          navigate(`/profile/${user.userID}`);
        },
      },
      {
        label: t("navbar.rightMenu.requestInternsTab"),
        key: "requestInterns",
        icon: <FormOutlined />,
        onClick: () => {
          navigate("/request-form");
        },
      },
      {
        label: t("navbar.rightMenu.myRequestsTab"),
        key: "myRequests",
        icon: <TableOutlined />,
        onClick: () => {
          navigate("/requests");
        },
      },
    );

  subMenuItems.push(
    {
      type: "divider",
    },
    {
      label: currentLanguage === "ar" ? "EN" : "AR",
      key: "switchLanguage",
      icon: <GlobalOutlined />,
      onClick: handleSwitchLanguage,
    },
    {
      label: t("navbar.rightMenu.logout"),
      key: "logout",
      icon: <LogoutOutlined />,
      onClick: async () => {
        await logout();
        navigate("/");
      },
    },
  );

  const menuItems = isAuthenticated
    ? [
        {
          key: "subMenu",
          label: (
            <>
              <Avatar icon={<UserOutlined />} />
              <span
                className="username"
                style={currentLanguage === "en" ? { marginLeft: "10px" } : { marginRight: "10px" }}
              >
                {user?.email}
              </span>
            </>
          ),
          children: subMenuItems,
        },
      ]
    : [
        {
          label: currentLanguage === "ar" ? "EN" : "AR",
          key: "switchLanguage",
          onClick: handleSwitchLanguage,
        },
        {
          label: (
            <>
              <Button type="primary" onClick={() => navigate("/user-type")} className="signUpBtn">
                {t("navbar.rightMenu.signUp")}
              </Button>

              <Divider
                type="vertical"
                className="ms-2 me-4"
                style={{ borderWidth: "2px", height: "20px" }}
              />

              <Button type="text" onClick={() => navigate("/sign-in")} className="signInBtn">
                {t("navbar.rightMenu.signIn")}
              </Button>
            </>
          ),
          key: "signIn",
        },
      ];

  return (
    <div
      className="rightMenu"
      style={{
        minWidth: isAuthenticated ? "200px" : "350px",
      }}
    >
      <Menu mode={mode} items={menuItems} selectedKeys={[]} />
    </div>
  );
};

export default RightMenu;
