import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Form, Row, Col, Button, Checkbox, Typography } from "antd";

import RequestFormContainer from "./styles";

import ProgramInfoForm from "./ProgramInfoForm";
import InterviewersInfoForm from "./InterviewersInfoForm";
import LocationsForm from "./LocationsForm";

import { NEW_COMPANY_REQUEST_MUTATION } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";

const { Title } = Typography;

const CompanyRequestFrom = () => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const errorMessageRef = useRef(null);
  const successMessageRef = useRef(null);

  const [submitting, setSubmitting] = useState(false);

  const [interviewersInfoInputFields, setInterviewersInfoInputFields] = useState([
    { name: "", number: "" },
    { name: "", number: "" },
  ]);

  const [locationsInputFields, setLocationsInputFields] = useState([
    {
      address: "",
      url: "",
      supervisorName: "",
      supervisorNumber: "",
      jobOpportunities: [
        {
          jobTitle: "",
          jobDescription: "",
          malesNumber: "",
          femalesNumber: "",
          startingTime: "",
          endingTime: "",
          specialNotes: "",
        },
      ],
    },
  ]);

  const [certificateCopy, setCertificateCopy] = useState(null);

  const [newCompanyRequestMutation] = useMutation(NEW_COMPANY_REQUEST_MUTATION, {
    onCompleted: (data) => {
      if (data.newCompanyRequest.success) {
        setErrorMessage(null);
        setSuccessMessage(t("requestForm.submittedSuccessfullyMessage"));
        scrollToSuccess();
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000); // Clear success message after 5 seconds
      } else {
        setErrorMessage(data.newCompanyRequest.errors[0]);
        scrollToError();
        setSubmitting(false);
      }
    },
  });

  const onFinish = (values) => {
    setSubmitting(true);

    const data = {
      ...values,
      certificateCopy,
      interviewersInfoForm: interviewersInfoInputFields,
      locationsInfoForm: locationsInputFields,
    };

    newCompanyRequestMutation({
      variables: { ...data },
    });
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  const scrollToError = () => {
    if (errorMessageRef.current) {
      errorMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const scrollToSuccess = () => {
    if (successMessageRef.current) {
      successMessageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <RequestFormContainer>
      <Title
        className="mb-3 mt-0"
        style={{
          fontSize: "30px",
          whiteSpace: "break-spaces",
          textAlign: "center",
        }}
      >
        {t("requestForm.formTitle")}
      </Title>

      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        className="row-col"
        scrollToFirstError={true}
      >
        <div ref={errorMessageRef}></div> {/* Error message ref */}
        {errorMessage && <p className="text-danger english-field">{errorMessage}</p>}
        <div ref={successMessageRef}></div> {/* Success message ref */}
        {successMessage && <p className="text-success">{successMessage}</p>}
        <Row>
          <Col xs={24}>
            <ProgramInfoForm {...{ setCertificateCopy }} />
          </Col>

          <Col xs={24}>
            <InterviewersInfoForm
              inputFields={interviewersInfoInputFields}
              setInputFields={setInterviewersInfoInputFields}
            />
          </Col>

          <Col xs={24}>
            <LocationsForm
              inputFields={locationsInputFields}
              setInputFields={setLocationsInputFields}
            />
          </Col>
        </Row>
        <>
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <Form.Item
                valuePropName="checked"
                name="accurateInformation"
                rules={[
                  {
                    required: true,
                    message: t("requestForm.requiredFieldMessage"),
                  },
                ]}
              >
                <Checkbox>{t("requestForm.accurateInformationField")}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 0]} justify="center">
            <Col xs={24} md={12}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  loading={submitting}
                  disabled={submitting}
                >
                  {t("requestForm.submitBtn")}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    </RequestFormContainer>
  );
};

export default CompanyRequestFrom;
